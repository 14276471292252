import TypingEffect from "../../assets/utils/TypingEffect";

export default function Intro() {
  return (
    <>
      <div className="relative isolate px-6 lg:px-8">
        <div className="flex h-screen">
          <div className="m-auto">
            <div className="text-center" style={{ paddingTop: "70px" }}>
              <p className="text-lg leading-8 text-gray-600">Coming Soon</p>
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl intro-name">
                {"<Izzat />"}
              </h1>
              <p className="mt-5 text-lg leading-8 text-gray-600">
                <TypingEffect text="Aspiring Software Enginner" />
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="https://www.linkedin.com/in/muhammad-izzat-sg/"
                  className="rounded-md bg-blue-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  target="blank_"
                >
                  LinkedIn <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div
              className="hidden sm:mb-8 sm:flex sm:justify-center mt-10"
              style={{ cursor: "pointer" }}
            >
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                <div className="status__light">
                  <div className="status__light__ring"></div>
                  <div className="status__light__led"></div>
                </div>
                <p className="status__message">COMING SOON</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
