import "./App.css";
import Intro from "./component/hero/intro";
function App() {
  return (
    <>
      <Intro />
    </>
  );
}

export default App;
